<template>
    <v-container fluid="fluid" class="transparent">
        <v-card v-if="!view.payMyBill" outlined="outlined">
            <v-card-text v-if="view.index&&(canada?!form.type:false)">
                <div class="overline mb-4 text-left">
                    {{ $t('header.title') }}
                </div>
                <div class="text--primary text-center">
                    {{ $t('header.instructions.formTypeFalse') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.index&&(canada?form.type:true)">
                <div class="overline mb-4 text-left">
                    {{ $t('header.title') }}
                </div>
                <div class="text--primary text-center">
                    {{ $t('header.instructions.formTypeTrue') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.contactUs">
                <div class="overline mb-4 text-left">
                    {{ $t('generalInquiry.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('generalInquiry.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.landfill">
                <div class="overline mb-4 text-left">
                    <!-- {{ $t('landfillInquiry.title') }} -->
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('landfillInquiry.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.bulkItemPickup">
                <div class="overline mb-4 text-left">
                    {{ $t('bulkItemPickup.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('bulkItemPickup.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.missedPickup">
                <div class="overline mb-4 text-left">
                    {{ $t('missedPickup.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('missedPickup.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.brokenContainer">
                <div class="overline mb-4 text-left">
                    {{ $t('brokenContainer.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('brokenContainer.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.deliveryIssue">
                <div class="overline mb-4 text-left">
                    {{ $t('deliveryIssue.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('deliveryIssue.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.iAmMoving||view.transferService">
                <div class="overline mb-4 text-left">
                    {{ $t('iAmMovingAndTransferService.title') }}
                </div>
                <div v-if="view.iAmMoving" class="mb-4 text--primary text-center">
                    {{ $t('iAmMoving.instructions') }}
                </div>
                <div v-if="view.transferService" class="mb-4 text--primary text-center">
                    {{ $t('transferService.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.cancelService">
                <div class="overline mb-4 text-left">
                    {{ $t('cancelService.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('cancelService.instructionsPart1') }} {{ phone }} {{ $t('cancelService.instructionsPart2') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.stopService">
                <div class="overline mb-4 text-left">
                    {{ $t('stopService.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('stopService.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.serviceUpgrade">
                <div class="overline mb-4 text-left">
                    {{ $t('serviceUpgrade.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('serviceUpgrade.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.vacation">
                <div class="overline mb-4 text-left">
                    {{ $t('vacation.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('vacation.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.driverReview">
                <div class="overline mb-4 text-left">
                    {{ $t('driverReview.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('driverReview.mainInstructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.requestQuote">
                <div class="overline mb-4 text-left">
                    {{ $t('requestQuote.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('requestQuote.mainInstructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.startService">
                <div class="overline mb-4 text-left">
                    {{ $t('startService.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('startService.mainInstructions') }}
                </div>
            </v-card-text>
            <v-divider v-if="!view.cancelService"></v-divider>
            <v-card-actions v-if="view.index">
                <v-container class="pa-0">
                    <v-row v-if="canada" no-gutters="no-gutters" class="pa-2">
                        <v-col>
                            <v-bottom-navigation v-model="form.type" dark="dark" class="ma-auto rounded" color="white" background-color="secondary" height="80" width="368" grow="grow">
                                <v-btn value="Residential" class="mx-2" text="text" active-class="active">
                                    <span class="text-button mt-1">{{ $t('serviceLocationType.home') }}</span>
                                    <v-icon large="large">mdi-home-variant-outline</v-icon>
                                </v-btn>
                                <v-divider inset="inset" vertical="vertical"></v-divider>
                                <v-btn value="Commercial" class="mx-2" text="text" active-class="active">
                                    <span class="text-button mt-1">{{ $t('serviceLocationType.business') }}</span>
                                    <v-icon large="large">mdi-store-outline</v-icon>
                                </v-btn>
                            </v-bottom-navigation>
                        </v-col>
                    </v-row>
                    <div v-if="!services.length">
                        <v-row v-if="canada?form.type:true" no-gutters="no-gutters">
                            <v-col v-if="form.type!=='Residential'" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('bulkItemPickup')">
                                    {{ $t('serviceOptions.bulkItemPickup') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('startService')">
                                    {{ $t('serviceOptions.startService') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="canada?form.type:true" no-gutters="no-gutters">
                            <v-col v-if="form.type!=='Residential'" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('brokenContainer')">
                                    {{ $t('serviceOptions.brokenContainer') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('transferService')">
                                    {{ $t('serviceOptions.transferService') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('missedPickup')">
                                    {{ $t('serviceOptions.missedPickup') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="canada?form.type:true" no-gutters="no-gutters">
                            <v-col class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('driverReview')">
                                    {{ $t('serviceOptions.driverReview') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('iAmMoving')">
                                    {{ $t('serviceOptions.iAmMoving') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('serviceUpgrade')">
                                    {{ $t('serviceOptions.serviceUpgrade') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="canada?form.type:true" no-gutters="no-gutters">
                            <v-col v-if="form.type!=='Residential'&&!stopservice" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('cancelService')">
                                    {{ $t('serviceOptions.cancelService') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'&&stopservice" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('stopService')">
                                    {{ $t('serviceOptions.stopService') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('deliveryIssue')">
                                    {{ $t('serviceOptions.deliveryIssue') }}
                                </v-btn>
                            </v-col>
                            <v-col class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('contactUs')">
                                    {{ $t('serviceOptions.generalInquiry') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="canada?form.type:true" no-gutters="no-gutters">
                            <v-col v-if="form.type!=='Residential'" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('requestQuote')">
                                    {{ $t('serviceOptions.requestQuote') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('vacation')">
                                    {{ $t('serviceOptions.vacation') }}
                                </v-btn>
                            </v-col>
                            <v-col class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('payMyBill')">
                                    {{ $t('serviceOptions.payMyBill') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="services.length">
                        <v-row no-gutters="no-gutters">
                            <v-col v-for="(service, index) in serviceItems" :key="index" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" :color="index%2?'secondary':'primary'" v-on:click="viewForm(service.action)">
                                    {{ service.name }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </v-card-actions>
            <v-card-text v-if="view.iAmMoving">
                <v-alert class="ma-0" dense="dense" outlined="outlined" prominent="prominent" text="text" color="orange" type="info">
                    <v-row align="center">
                        <v-col class="grow">
                            {{ $t('iAmMoving.transferServiceCardText') }}
                        </v-col>
                        <v-col class="shrink">
                            <v-btn outlined="outlined" color="orange" v-on:click="viewForm('transferService')">
                                {{ $t('iAmMoving.transferServiceCardBtn') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-text>
            <v-card-text v-if="false"><!--view.missedPickup||view.brokenContainer||view.deliveryIssue||view.iAmMoving||view.serviceUpgrade-->
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{ $t('districtPolicy.title') }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            {{ $t('districtPolicy.text') }}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
            <v-divider v-if="view.missedPickup||view.brokenContainer||view.deliveryIssue||view.iAmMoving"></v-divider>
            <v-card-text v-if="!view.index&&!view.landfill&&!light" v-bind:class="view.requestQuote || view.startService?'pr-0 pl-0 pb-0':'pb-0'">
                <v-row v-bind:class="view.cancelService?'mb-0':''">
                    <v-col class="text-center">
                        <v-btn small="small" outlined="outlined" text="text" v-bind:disabled="loading" v-on:click="resetAll()">
                            <v-icon left="left">
                                mdi-restart
                            </v-icon>
                            {{ $t('universalFormContent.backBtn') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <RequestQuoteCmp v-if="view.requestQuote || view.startService" :serviceicons="serviceicons" :assigntodistrict="assigntodistrict" :lang="lang" :country="canada?'canada':''" :terms="terms" :privacy="privacy" :name="name" widget/>
            </v-card-text>
            <v-card-text v-if="view.address||view.prediction">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container v-if="view.address">
                        <v-row>
                            <v-col cols="12" class="pa-3">
                                <v-text-field ref="autocompleteInput" dense="dense" outlined="outlined" v-model="form.searchPlace" v-bind:rules="formRules.searchRules" prepend-icon="mdi-map-search-outline" :placeholder="$t('universalFormContent.fields.address')" single-line="single-line" v-bind:disabled="loading"></v-text-field><!--label="Address"-->
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-text-field dense="dense" outlined="outlined" v-model="form.address" v-bind:rules="formRules.addressRules" prepend-icon="mdi-map-marker-outline" :label="$t('universalFormContent.fields.streetAddress')" v-bind:disabled="loading" required="required"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-text-field dense="dense" outlined="outlined" v-model="form.city" v-bind:rules="formRules.cityRules" prepend-icon="mdi-home-city-outline" :label="$t('universalFormContent.fields.city')" v-bind:disabled="loading" required="required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-text-field dense="dense" outlined="outlined" v-model="form.state" v-bind:rules="formRules.stateRules" prepend-icon="mdi-home-group" :label="canada?$t('universalFormContent.fields.province'):$t('universalFormContent.fields.state')" v-bind:disabled="loading" required="required"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-text-field dense="dense" outlined="outlined" v-model="form.zip" v-bind:rules="formRules.zipRules" prepend-icon="mdi-code-equal" :label="canada?$t('universalFormContent.fields.postal'):$t('universalFormContent.fields.zip')" v-bind:disabled="loading" required="required"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container v-if="view.prediction">
                        <v-row>
                            <v-col>
                                <v-list dense="dense" two-line="two-line" subheader="subheader" v-bind:disabled="loading">
                                    <v-subheader class="justify-center text-center">{{ $t('predictions.subheader') }}</v-subheader>
                                    <v-list-item-group v-model="form.predictionsSelected" mandatory="mandatory" active-class="secondary--text">
                                        <template v-for="(prediction, index) in form.predictions">
                                            <v-list-item two-line v-bind:key="'L'+index" v-bind:value="prediction.description">
                                                <template v-slot:default="{ active }">
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ prediction.structured_formatting.main_text }}</v-list-item-title>
                                                        <v-list-item-subtitle class="text--primary">{{ prediction.structured_formatting.secondary_text }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-icon v-if="!active">
                                                            mdi-checkbox-blank-circle-outline
                                                        </v-icon>
                                                        <v-icon v-else color="primary">
                                                            mdi-check-circle-outline
                                                        </v-icon>
                                                    </v-list-item-action>
                                                </template>
                                            </v-list-item>
                                            <v-divider v-if="index < form.predictions.length - 1" v-bind:key="'D'+index"></v-divider>
                                        </template>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-slide-y-transition hide-on-leave>
                    <v-container v-if="!valid">
                        <v-row class="mt-0">
                            <v-col class="text-center">
                                <span class="text-caption error--text"><v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon>{{ $i18n.t('universalFormContent.itemsAttention') }}</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-slide-y-transition>
            </v-card-text>
            <v-card-text v-if="!view.index&&(!view.address&&!view.prediction&&view.main)&&!view.cancelService&&!view.requestQuote&&!view.startService&&!view.payMyBill" class="pb-0">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <!-- <v-row>
                            <v-col cols="12" class="pa-3">
                                <h4 v-if="view.transferService" class="v-heading text-h6 mb-2 pl-8">
                                    {{ $t('transferService.fields.currentAddress') }}
                                </h4>
                                <v-text-field ref="autocompleteInput" dense="dense" outlined="outlined" v-model="form.address" v-bind:rules="formRules.addressRules" prepend-icon="mdi-map-search-outline" :placeholder="$t('universalFormContent.fields.address')" single-line="single-line" v-bind:disabled="loading" required="required"></v-text-field>
                            </v-col>
                        </v-row> -->
                        <v-row>
                            <v-col cols="12" class="pa-3">
                                <v-card outlined="outlined" class="flex-grow-1">
                                    <v-list-item dense="dense">
                                        <v-list-item-content>
                                            <v-icon large="large" color="accent">mdi-map-marker-outline</v-icon>
                                            <div class="text-primary text-center">{{form.searchPlace}}</div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="mb-0">
                            <v-col class="text-center">
                                <v-btn small="small" outlined="outlined" text="text" v-bind:disabled="loading" v-on:click="viewStep('address')">
                                    <v-icon left="left">
                                        mdi-pencil
                                    </v-icon>
                                    {{ $t('universalFormContent.editAddressBtn') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.transferService">
                            <v-col cols="12" class="pa-3">
                                <h4 v-if="view.transferService" class="v-heading text-h6 mb-2 pl-8 text-left">
                                    {{ $t('transferService.fields.newAddress') }}
                                </h4>
                                <v-text-field ref="autocompleteInputTo" dense="dense" outlined="outlined" v-model="form.addressTo" v-bind:rules="formRules.addressRules" prepend-icon="mdi-map-search-outline" :placeholder="$t('universalFormContent.fields.addressTo')" single-line="single-line" v-bind:disabled="loading" required="required"></v-text-field><!--label="Address"-->
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-text-field dense="dense" outlined="outlined" v-model="form.name" v-bind:rules="formRules.nameRules" prepend-icon="mdi-form-textbox" :label="$t('universalFormContent.fields.name')" v-bind:disabled="loading" required="required"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-text-field dense="dense" outlined="outlined" v-model="form.phone" type="tel" v-bind:rules="formRules.phoneRules" prepend-icon="mdi-phone-outline" :label="$t('universalFormContent.fields.phone')" v-bind:disabled="loading" required="required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-text-field dense="dense" outlined="outlined" v-model="form.email" type="email" v-bind:rules="formRules.emailRules" prepend-icon="mdi-email-outline" :label="$t('universalFormContent.fields.email')" v-bind:disabled="loading" required="required"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-select v-if="view.contactUs&&!light" v-bind:rules="formRules.requiredRules" dense="dense" outlined="outlined" v-model="form.containerOut" v-bind:items="generalIssueItems" prepend-icon="mdi-chat-question-outline" :label="$t('generalInquiry.fields.generalIssue')" attach="attach" v-bind:disabled="loading"></v-select>
                                <v-textarea v-if="view.landfill||light" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('landfillInquiry.fields.description.label')" :hint="$t('contactUs.fields.description.hint')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.bulkItemPickup" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('bulkItemPickup.fields.description.label')" :hint="$t('bulkItemPickup.fields.description.hint')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.missedPickup" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('missedPickup.fields.description.label')" :hint="$t('missedPickup.fields.description.hint')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.brokenContainer||view.deliveryIssue||view.iAmMoving||view.transferService" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('contentForManyServices.fields.description.additionalDetailsLabel')" :hint="$t('contentForManyServices.fields.description.additionalDetailsHint')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.serviceUpgrade" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" label="Description" :hint="$t('serviceUpgrade.fields.description.label')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.vacation||view.stopService" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('contentForManyServices.fields.description.additionalDetailsLabel')" :hint="$t('contentForManyServices.fields.description.hintVacationAndStopService')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.driverReview" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('driverReview.fields.description.label')" :hint="$t('driverReview.fields.description.hint')" v-bind:disabled="loading"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.contactUs||view.missedPickup||view.deliveryIssue||view.iAmMoving||view.transferService">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-textarea v-if="view.contactUs&&!light" :required="form.containerOut==='Other'?true:false" :rules="form.containerOut==='Other'?formRules.requiredDescriptionRules:formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" label="Description" :hint="$t('contactUs.fields.description.hint')" v-bind:disabled="loading"></v-textarea>
                                <v-menu v-if="view.missedPickup" v-model="form.datePickupMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.datePickupFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('missedPickup.fields.date')" prepend-icon="mdi-calendar" @click:prepend="form.datePickupMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.datePickup" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.datePickupMenu = false"></v-date-picker>
                                </v-menu>
                                <v-menu v-if="view.deliveryIssue" v-model="form.datePickupMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.datePickupFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('deliveryIssue.fields.date')" prepend-icon="mdi-calendar" @click:prepend="form.datePickupMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.datePickup" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.datePickupMenu = false"></v-date-picker>
                                </v-menu>
                                <v-menu v-if="view.iAmMoving||view.transferService" v-model="form.datePickupMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.datePickupFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('contentForManyServices.fields.lastServiceDate')" prepend-icon="mdi-calendar" @click:prepend="form.datePickupMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.datePickup" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.datePickupMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-combobox v-if="view.missedPickup" dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.containerOut" v-bind:rules="formRules.requiredRules" v-bind:items="missedPickupItems" :label="$t('missedPickup.fields.containerOut')" attach="attach" prepend-icon="mdi-crosshairs-question" v-bind:disabled="loading"></v-combobox>
                                <v-combobox v-if="view.deliveryIssue" dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.containerOut" v-bind:rules="formRules.requiredRules" v-bind:items="deliveryIssueItems" :label="$t('deliveryIssue.fields.containerOut')" attach="attach" prepend-icon="mdi-crosshairs-question" v-bind:disabled="loading"></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.deliveryIssue">
                            <v-col>
                                <v-file-input dense="dense" outlined="outlined" chips="chips" small-chips="small-chips" show-size="show-size" accept="image/*" v-model="form.fileUpload" v-bind:rules="formRules.resumeRules" :label="$t('deliveryIssue.fields.image')" :hint="$t('deliveryIssue.fields.imageHint')" persistent-hint="persistent-hint" v-bind:disabled="loading"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.missedPickup">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-combobox dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.cartType" v-bind:rules="formRules.requiredRules" v-bind:items="cartTypeItems" :label="$t('missedPickup.fields.cartType')" attach="attach" prepend-icon="mdi-delete-outline" v-bind:disabled="loading"></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.brokenContainer">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-combobox dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.containerIssue" v-bind:rules="formRules.requiredRules" v-bind:items="brokenContainerIssueItems" :label="$t('brokenContainer.fields.issue')" attach="attach" prepend-icon="mdi-alert-outline" v-bind:disabled="loading"></v-combobox><!-- Container issue -->
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-combobox dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.cartType" v-bind:rules="formRules.requiredRules" v-bind:items="cartTypeItems" :label="$t('brokenContainer.fields.cartType')" attach="attach" prepend-icon="mdi-delete-outline" v-bind:disabled="loading"></v-combobox><!-- Type of cart that is broken/lost -->
                            </v-col>
                        </v-row>
                        <v-row v-if="view.brokenContainer">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-menu v-model="form.datePickupMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.datePickupFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('brokenContainer.fields.date')" prepend-icon="mdi-calendar" @click:prepend="form.datePickupMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.datePickup" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.datePickupMenu = false"></v-date-picker>
                                </v-menu><!-- Date container was broken/lost -->
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-combobox v-if="form.containerIssue==='Broken'" dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.containerOut" v-bind:rules="formRules.requiredRules" v-bind:items="brokenContainerPartItems" :label="$t('brokenContainer.fields.containerOut')" attach="attach" prepend-icon="mdi-crosshairs-question" v-bind:disabled="loading"></v-combobox><!-- What is broken? -->
                            </v-col>
                        </v-row>
                        <v-row v-if="view.serviceUpgrade">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-select dense="dense" outlined="outlined" v-model="form.upgradeService" v-bind:rules="formRules.requiredRules" v-bind:items="upgradeServiceItems" item-text="name" item-value="name" max-height="auto" autocomplete="autocomplete" multiple="multiple" attach="attach" v-bind:menu-props="{auto:true,offsetY:true}" prepend-icon="mdi-crosshairs-question" :label="$t('serviceUpgrade.fields.upgradeChoices')" v-bind:disabled="loading"></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.vacation||view.stopService">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-menu v-model="form.pickupBeforeMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.pickupBeforeFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('contentForManyServices.fields.lastPickupDate')" prepend-icon="mdi-calendar" @click:prepend="form.pickupBeforeMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.pickupBefore" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.pickupBeforeMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-menu v-if="view.vacation" v-model="form.pickupAfterMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.pickupAfterFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('vacation.fields.firstPickupDate')" prepend-icon="mdi-calendar" @click:prepend="form.pickupAfterMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.pickupAfter" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.pickupAfterMenu = false"></v-date-picker>
                                </v-menu>
                                <v-combobox v-if="view.stopService" dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.stopRequestStatus" v-bind:rules="formRules.requiredRules" v-bind:items="stopServiceItems" :label="$t('stopService.fields.requestStatus')" attach="attach" prepend-icon="mdi-crosshairs-question" v-bind:disabled="loading"></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.driverReview">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-menu v-model="form.driverTimeMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.driverTimeFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('driverReview.fields.date')" prepend-icon="mdi-calendar" @click:prepend="form.driverTimeMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.driverTime" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.driverTimeMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 align-start">
                                <span class="mr-2">
                                    {{ $t('driverReview.fields.rating') }} ({{ form.driverRating }})
                                </span>
                                <v-rating v-model="form.driverRating" background-color="primary" color="primary" half-increments="half-increments" hover="hover" x-large="x-large" v-bind:disabled="loading"></v-rating>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.iAmMoving">
                            <v-col>
                                <v-checkbox v-model="form.rollOffBox" :label="$t('iAmMoving.fields.rollOffBox')" v-bind:disabled="loading"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.transferService">
                            <v-col>
                                <v-checkbox v-model="form.rollOffBox" :label="$t('transferService.fields.rollOffBox')" v-bind:disabled="loading"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-checkbox v-model="form.checkbox" :label="$t('universalFormContent.fields.rememberMe')" v-bind:disabled="loading"></v-checkbox>
                        <v-checkbox v-model="form.acceptConditions" v-bind:rules="formRules.acceptConditions" v-bind:disabled="loading" required="required">
                            <template slot="label">
                                <div>
                                    {{ $t('universalFormContent.fields.termsAndConditions.part1') }} {{$i18n.locale=='en'?name:''}}
                                    <v-tooltip bottom="bottom" attach="attach">
                                        <template v-slot:activator="{ on }">
                                            <a target="_blank" rel="nofollow noopener" v-bind:href="terms" v-on:click.stop="return false" v-on="on">
                                                {{ $t('universalFormContent.fields.termsAndConditions.part2') }}
                                            </a>
                                        </template>
                                        {{ $t('universalFormContent.fields.termsAndConditions.part2') }}
                                    </v-tooltip>
                                    {{ $t('universalFormContent.fields.termsAndConditions.part3') }}
                                    <v-tooltip bottom="bottom" attach="attach">
                                        <template v-slot:activator="{ on }">
                                            <a target="_blank" rel="nofollow noopener" v-bind:href="privacy" v-on:click.stop="return false" v-on="on">
                                                {{ $t('universalFormContent.fields.termsAndConditions.part4') }}
                                            </a>
                                        </template>
                                        {{ $t('universalFormContent.fields.termsAndConditions.part4') }}
                                    </v-tooltip>
                                    {{ $t('universalFormContent.fields.termsAndConditions.part5') }} {{$i18n.locale=='fr'?name:''}}
                                </div>
                            </template>
                        </v-checkbox>
                    </v-container>
                </v-form>
                <v-slide-y-transition hide-on-leave>
                    <v-container v-if="!valid">
                        <v-row class="mt-0">
                            <v-col class="text-center">
                                <span class="text-caption error--text"><v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon>{{ $i18n.t('universalFormContent.itemsAttention') }}</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-slide-y-transition>
            </v-card-text>
            <v-divider v-if="!view.index"></v-divider>
            <v-card-actions v-if="view.address">
                <v-btn :color="!valid?'red':'secondary'" block="block" v-ripple="loading ? false : true" v-on:click="loading ? null : validate()">
                    {{loading ? $t('universalFormContent.validate') : $t('universalFormContent.next')}}
                    <v-icon v-if="!loading" right="right">mdi-chevron-right</v-icon>
                    <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                </v-btn>
            </v-card-actions>
            <v-card-actions v-if="view.prediction" class="justify-space-between">
                <v-btn class="flex-grow-1" color="secondary" v-ripple="loading ? false : true" v-on:click="loading ? null : viewStep('address')">
                    <v-icon left="left">mdi-chevron-left</v-icon>
                    {{$t('universalFormContent.backBtn')}}
                </v-btn>
                <v-btn class="flex-grow-1" color="secondary" v-ripple="loading ? false : true" v-on:click="loading ? null : selectedPrediction(form.predictionsSelected,'address')">
                    {{loading ? $t('universalFormContent.validate') : $t('universalFormContent.next')}}
                    <v-icon v-if="!loading" right="right">mdi-chevron-right</v-icon>
                    <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                </v-btn>
            </v-card-actions>
            <v-card-actions v-if="!view.index&&!view.address&&!view.prediction&&!view.cancelService&&!view.requestQuote&&!view.startService&&!view.payMyBill">
                <v-btn :color="!valid?'red':'secondary'" block="block" v-ripple="loading ? false : true" v-on:click="loading ? null : view.transferService&&view.main?autocompleteSuggestions('addressTo'):verificationByAddress()">
                    {{loading ? $t('universalFormContent.loading') : $t('universalFormContent.submit')}}
                    <v-icon v-if="!loading" right="right">mdi-send-outline</v-icon>
                    <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                </v-btn>
            </v-card-actions>
            <v-card-actions v-if="!view.index&&!view.cancelService&&!view.requestQuote&&!view.startService&&!view.payMyBill">
                <span class="text-caption text--disabled ma-auto"><v-icon class="text--disabled" left="left" dense="dense">mdi-lock-outline</v-icon>{{ $t('universalFormContent.recaptcha') }}</span>
            </v-card-actions>
        </v-card>
        <v-col v-if="view.payMyBill">
            <div class="title mb-4 text-center">
                {{ $t('universalFormContent.mainLoading') }}
            </div>
        </v-col>
        <v-row class="mb-0" justify="center">
            <v-dialog v-model="questionDialog" persistent="persistent" attach="attach" hide-overlay max-width="644">
                <v-card>
                    <v-card-title class="headline">
                        {{ $t('universalFormContent.accountLocated') }}
                    </v-card-title>
                    <v-card-text>
                        {{ $t('universalFormContent.selectAnswersIdentity.instructions') }}
                        <v-form ref="apiValues" v-model="valid" lazy-validation>
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-select dense="dense" outlined="outlined" v-model="apiValues.answerName" v-bind:items="apiValues.questionsName" prepend-icon="mdi-form-textbox" :menu-props="{maxHeight:150}"
                                            :label="$t('universalFormContent.selectAnswersIdentity.name')" required="required" v-bind:rules="formRules.requiredRules" attach="attach" v-bind:disabled="loading"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-select dense="dense" outlined="outlined" v-model="apiValues.answerEmail" v-bind:items="apiValues.questionsEmail" prepend-icon="mdi-email-outline" :menu-props="{maxHeight:150}"
                                            :label="$t('universalFormContent.selectAnswersIdentity.email')" required="required" v-bind:rules="formRules.requiredRules" attach="attach" v-bind:disabled="loading"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-select dense="dense" outlined="outlined" v-model="apiValues.answerPhone" v-bind:items="apiValues.questionsPhone" prepend-icon="mdi-phone-outline" :menu-props="{maxHeight:150}"
                                            :label="$t('universalFormContent.selectAnswersIdentity.phone')" required="required" v-bind:rules="formRules.requiredRules" attach="attach" v-bind:disabled="loading"></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn outlined="outlined" color="primary" width="35%" v-on:click="resetDialog" v-bind:disabled="loading">
                            <v-icon left="left">mdi-cancel</v-icon>
                            {{ $t('universalFormContent.cancel') }}
                        </v-btn>
                        <v-btn outlined="outlined" color="secondary" width="65%" v-ripple="loading?false:true" v-on:click="loading?null:validateAnswers()">
                            {{loading?$t('universalFormContent.loading'):$t('universalFormContent.submit')}}
                            <v-icon v-if="!loading" right="right">mdi-send-outline</v-icon>
                            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="standardDialog" persistent="persistent" attach="attach" hide-overlay max-width="644">
                <v-card>
                    <v-card-title class="headline">
                        {{textDialog.title}}
                    </v-card-title>
                    <v-card-text class="text-left">
                        {{textDialog.feedback}}
                    </v-card-text>
                    <v-card-text v-if="textDialog.googleRating!==''">
                        {{textDialog.googleRating}}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn outlined="outlined" color="primary" width="25%" v-on:click="resetDialog">
                            <v-icon left="left">mdi-close-circle-outline</v-icon>
                            {{ $t('universalFormContent.close') }}
                        </v-btn>
                        <v-btn v-if="textDialog.buttonLocations" width="74%" outlined="outlined" color="secondary" v-bind:href="map">
                            {{ $t('universalFormContent.locationsMap') }}
                            <v-icon right="right">mdi-map-search-outline</v-icon>
                        </v-btn>
                        <v-btn v-if="textDialog.buttonGoogleRating" width="74%" outlined="outlined" color="secondary" href="https://www.google.com" target="_blank" rel="nofollow noopener">
                            {{ $t('universalFormContent.googleReview') }}
                            <v-icon right="right">mdi-message-draw</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="recaptchaDialog" attach hide-overlay max-width="290">
                <v-card>
                    <v-card-title class="text-h5 d-flex justify-center">
                        <v-icon x-large color="primary">mdi-alert-circle-outline</v-icon>
                    </v-card-title>
                    <v-card-text class="text-center">{{ $i18n.t('modals.snackbar.body1') }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="recaptchaDialog = false">
                            {{ $i18n.t('modals.snackbar.close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import axios from 'axios';
import { load } from 'recaptcha-v3';
import Components from 'request-quote-cmp';
import { loadAdditionalLibrary } from '../plugins/loadAdditionalLibrary';
// import gtag, { install } from 'ga-gtag';
// import {init, track} from 'fbq';
// install(process.env.VUE_APP_TRACKING);
// init(process.env.VUE_APP_FBID);
export default {
    name: 'ContactUs',
    components: {
        ...Components
    },
    props: {
        canada: Boolean,
        service: String,
        services: String,
        serviceicons: Boolean,
        assigntolocation: Number,
        assigntodistrict: Number,
        bulkitempickup: String,
        stopservice: Boolean,
        phone: String,
        lang: String,
        light: Boolean,
        terms: String,
        privacy: String,
        map: String,
        pay: String,
        name: String,
    },
    data: () => ({
        google: null,
        valid: true,
        loading: false,
        standardDialog: false,
        questionDialog: false,
        recaptchaDialog:false,
        minDate: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()).toISOString().slice(0,10),
        maxDate: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().slice(0,10),
        view: {
            index: true,
            contactUs: false,
            landfill: false,
            bulkItemPickup: false,
            missedPickup: false,
            brokenContainer: false,
            deliveryIssue: false,
            iAmMoving: false,
            transferService: false,
            cancelService: false,
            stopService: false,
            serviceUpgrade: false,
            vacation: false,
            driverReview: false,
            requestQuote: false,
            startService: false,
            payMyBill: false,
            address: false,
            prediction: false,
            main: false,
        },
        form: {
            searchPlace: '',
            prediction: null,
            predictionSelected: null,
            name: '',
            address: '',
            street: '',
            route: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            lat: '',
            lng: '',
            email: '',
            phone: '',
            description: '',
            type: undefined,
            rollOffBox: '',
            checkbox: '',
            acceptConditions: '',
            addressTo: '',
            datePickup: '',
            datePickupMenu: false,
            pickupBefore: '',
            pickupBeforeMenu: false,
            pickupAfter: '',
            pickupAfterMenu: false,
            containerOut: '',
            cartType: '',
            containerIssue: '',
            upgradeService: '',
            driverRating: 5,
            stopRequestStatus: '',
            driverTime: null,
            driverTimeMenu: false,
            fileUpload: null,
        },
        apiValues: {
            sessionID: '',
            apiResponse: '',
            questionsName: [],
            questionsEmail: [],
            questionsPhone: [],
            answerName: '',
            answerPhone: '',
            answerEmail: ''
        },
        textDialog: {
            title: '',
            feedback: '',
            googleRating: '',
            buttonLocations: false,
            buttonGoogleRating: false
        },
    }),
    mounted: function(){
        this.$i18n.locale = this.lang;
        if(this.service){
            this.viewForm(this.service, true);
        }
        if(this.getCookie('SiteSettings')){
            const obj = JSON.parse(this.getCookie('SiteSettings'));
            const objForm = this.form;
            const a = [objForm, obj];
            Object.assign(...a);
        }
        const loader = new Loader({
            apiKey: process.env.VUE_APP_GMID,
            version: "weekly",
            libraries: ["places"]
        });
        loader.load().then(async (google) => {
            this.google = google;

            // Ensure the places library is loaded with the correct API key if not already loaded
            await loadAdditionalLibrary(process.env.VUE_APP_GMID, 'places');

            if(this.service){
                this.mapsPlacesAutocomplete();
            }
        }).catch(e => {
            console.log(e);
        });
    },
    watch: {
        'view.address':function(val){if(val&&(!this.service||this.google)){this.$nextTick(function(){this.mapsPlacesAutocomplete();})}},
        'view.main':function(val){if(val&&this.view.transferService){this.$nextTick(function(){this.mapsPlacesAutocomplete();})}},
        'form.datePickup':function(){this.form.datePickupFormatted = this.form.datePickup?this.formatDate(this.form.datePickup, this.canada?'en_CA':''):null},
        'form.pickupBefore':function(){this.form.pickupBeforeFormatted = this.form.pickupBefore?this.formatDate(this.form.pickupBefore, this.canada?'en_CA':''):null},
        'form.pickupAfter':function(){this.form.pickupAfterFormatted = this.form.pickupAfter?this.formatDate(this.form.pickupAfter, this.canada?'en_CA':''):null},
        'form.driverTime':function(){this.form.driverTimeFormatted = this.form.driverTime?this.formatDate(this.form.driverTime, this.canada?'en_CA':''):null},
    },
    computed: {
        serviceItems() {
            let serviceList = [
                    {name:this.$i18n.t('serviceOptions.bulkItemPickup'),action:'bulkItemPickup'},
                    {name:this.$i18n.t('serviceOptions.startService'),action:'startService'},
                    {name:this.$i18n.t('serviceOptions.brokenContainer'),action:'brokenContainer'},
                    {name:this.$i18n.t('serviceOptions.transferService'),action:'transferService'},
                    {name:this.$i18n.t('serviceOptions.missedPickup'),action:'missedPickup'},
                    {name:this.$i18n.t('serviceOptions.driverReview'),action:'driverReview'},
                    {name:this.$i18n.t('serviceOptions.iAmMoving'),action:'iAmMoving'},
                    {name:this.$i18n.t('serviceOptions.serviceUpgrade'),action:'serviceUpgrade'},
                    {name:this.$i18n.t('serviceOptions.cancelService'),action:'cancelService'},
                    {name:this.$i18n.t('serviceOptions.stopService'),action:'stopService'},
                    {name:this.$i18n.t('serviceOptions.deliveryIssue'),action:'deliveryIssue'},
                    {name:this.$i18n.t('serviceOptions.generalInquiry'),action:'contactUs'},
                    {name:this.$i18n.t('serviceOptions.requestQuote'),action:'requestQuote'},
                    {name:this.$i18n.t('serviceOptions.vacation'),action:'vacation'},
                    {name:this.$i18n.t('serviceOptions.payMyBill'),action:'payMyBill'},
                ];
            if(!this.services.length){
                return serviceList;
            }else{
                return serviceList.filter(service => !this.services.includes(service.name));
            }
        },
        generalIssueItems() {
            let serviceList = [
                    this.$i18n.t('serviceOptions.bulkItemPickup'),
                    this.$i18n.t('serviceOptions.vacation'),
                    this.$i18n.t('serviceOptions.brokenContainer'),
                    this.$i18n.t('serviceOptions.transferService'),
                    this.$i18n.t('serviceOptions.missedPickup'),
                    this.$i18n.t('serviceOptions.iAmMoving'),
                    this.$i18n.t('serviceOptions.serviceUpgrade'),
                    this.$i18n.t('serviceOptions.deliveryIssue'),
                    this.$i18n.t('serviceOptions.requestQuote'),
                    this.$i18n.t('serviceOptions.startService'),
                    this.$i18n.t('serviceOptions.other'),
                ];
            if(!this.services.length){
                return serviceList;
            }else{
                return serviceList.filter(service => !this.services.includes(service));
            }
        },
        missedPickupItems() {
            return [
                this.$i18n.t('items.missedPickup.out'),
                this.$i18n.t('items.missedPickup.notOut'),
            ];
        },
        brokenContainerIssueItems() {
            return [
                this.$i18n.t('items.brokenContainer.issue.broken'),
                this.$i18n.t('items.brokenContainer.issue.lost'),
            ];
        },
        brokenContainerPartItems() {
            return [
                this.$i18n.t('items.brokenContainer.part.lid'),
                this.$i18n.t('items.brokenContainer.part.wheels'),
                this.$i18n.t('items.brokenContainer.part.container'),
            ];
        },
        cartTypeItems() {
            return [
                this.$i18n.t('items.cartType.trash'),
                this.$i18n.t('items.cartType.recycling'),
                this.$i18n.t('items.cartType.yardDebris'),
                this.$i18n.t('items.cartType.organics'),
            ];
        },
        deliveryIssueItems() {
            return [
                this.$i18n.t('items.deliveryIssue.location'),
                this.$i18n.t('items.deliveryIssue.container'),
            ];
        },
        upgradeServiceItems() {
            return [
                { header: this.$i18n.t('items.upgradeService.trash') },
                { name: this.$i18n.t('items.upgradeService.64gallon'), group: this.$i18n.t('items.upgradeService.trash') },
                { name: this.$i18n.t('items.upgradeService.96gallon'), group: this.$i18n.t('items.upgradeService.trash') },
                { name: this.$i18n.t('items.upgradeService.additional96gallon'), group: this.$i18n.t('items.upgradeService.trash') },
                { divider: true },
                { header: this.$i18n.t('items.upgradeService.recycling') },
                { name: this.$i18n.t('items.upgradeService.addRecycling'), group: this.$i18n.t('items.upgradeService.recycling') },
                { divider: true },
                { header: this.$i18n.t('items.upgradeService.yardDebris') },
                { name: this.$i18n.t('items.upgradeService.addYardDebris'), group: this.$i18n.t('items.upgradeService.yardDebris') }
            ];
        },
        stopServiceItems() {
            return [
                this.$i18n.t('items.stopService.notNeeded'),
                this.$i18n.t('items.stopService.moved'),
                this.$i18n.t('items.stopService.temporary'),
            ];
        },
        formRules() {
            return {
                nameRules: [
                    v => !!v || this.$i18n.t('formRules.name.required'),
                    v => (v && v.length >= 2 && v.length <= 100) || this.$i18n.t('formRules.name.nbCaracters'),
                    v => /^([A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]{1})+([[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+[,.]?[ ]?|[A-Za-z]+['-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]]?)+$/.test(v) || this.$i18n.t('formRules.name.invalid'),
                ],
                searchRules: [
                    v => (v.length <= 300) || this.$i18n.t('formRules.search.nbCaracters'),
                ],
                addressRules: [
                    v => !!v || this.$i18n.t('formRules.address.required'),
                    v => !!v && v.length >= 2 && v.length <= 300 || this.$i18n.t('formRules.address.nbCaracters'),
                ],
                cityRules: [
                    v => !!v || this.$i18n.t('formRules.city.required'),
                    v => (v && v.length >= 2 && v.length <= 100) || this.$i18n.t('formRules.city.nbCaracters'),
                ],
                stateRules: [
                    v => !!v || this.$i18n.t('formRules.state.required'),
                    v => (v && v.length >= 2 && v.length <= 100) || this.$i18n.t('formRules.state.nbCaracters'),
                ],
                zipRules: [
                    v => !!v || this.$i18n.t('formRules.zip.required'),
                    v => (v && v.length >= 2 && v.length <= 100) || this.$i18n.t('formRules.zip.nbCaracters'),
                ],
                phoneRules: [
                    v => !!v || this.$i18n.t('formRules.phone.required'),
                    v => (v && v.length >= 2 && v.length <= 100) || this.$i18n.t('formRules.phone.nbCaracters'),
                    v => /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) || this.$i18n.t('formRules.phone.invalid'),
                ],
                emailRules: [
                    v => !!v || this.$i18n.t('formRules.email.required'),
                    v => /.+@.+\..+/.test(v) || this.$i18n.t('formRules.email.invalid'),
                ],
                acceptConditions: [
                    v => !!v || this.$i18n.t('formRules.acceptConditions'),
                ],
                descriptionRules: [
                    v => (v.length <= 600) || this.$i18n.t('formRules.description'),
                ],
                requiredRules: [
                    v => !!v || this.$i18n.t('formRules.required'),
                ],
                requiredDescriptionRules: [
                    v => !!v || this.$i18n.t('formRules.required'),
                    v => (v.length <= 600) || this.$i18n.t('formRules.description'),
                ],
                dateRules:[
                    v => !!v || this.$i18n.t('formRules.date.required'),
                    v => new Date(v) >= new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()) || this.$i18n.t('formRules.date.invalid'),//minDate
                    v => new Date(v) <= new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()) || this.$i18n.t('formRules.date.invalid'),//maxDate
                    v => /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(v) || this.$i18n.t('formRules.date.invalid'),
                ],
                dateCanadaRules:[
                    v => !!v || this.$i18n.t('formRules.date.required'),
                    v => new Date(new Date(v).getFullYear(), new Date(v).getMonth(), new Date().getDate()) >= new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()) || this.$i18n.t('formRules.date.invalid'),//minDate
                    v => new Date(new Date(v).getFullYear(), new Date(v).getMonth(), new Date().getDate()) <= new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()) || this.$i18n.t('formRules.date.invalid'),//maxDate
                    v => /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/.test(v) || this.$i18n.t('formRules.date.invalid'),
                ],
                resumeRules: [
                    v => !v || v.size < 4000000 || this.$i18n.t('formRules.resume.invalid'),
                ],
            }
        },
    },
    methods: {
        mapsPlacesAutocomplete(){
            var vm = this;
            if(!this.view.main){
                const autocomplete = new this.google.maps.places.Autocomplete(this.$refs.autocompleteInput.$el.querySelector('input'), {
                    componentRestrictions: { country: ["us", "ca"] },
                    fields: ["address_components", "formatted_address"],
                    types: ["address"],
                });
                autocomplete.addListener("place_changed", function() {
                    const place = autocomplete.getPlace();
                    if (!place.address_components){
                        return;
                    }else{
                        vm.form.address = vm.getAddressComponents(place.address_components, 'street_number', 'short_name')+' '+vm.getAddressComponents(place.address_components, 'route', 'short_name');
                        vm.form.street = vm.getAddressComponents(place.address_components, 'street_number', 'short_name');
                        vm.form.route = vm.getAddressComponents(place.address_components, 'route', 'short_name');
                        vm.form.city = vm.getAddressComponents(place.address_components, 'locality', 'short_name');
                        vm.form.state = vm.getAddressComponents(place.address_components, 'administrative_area_level_1', 'short_name');
                        vm.form.zip = vm.getAddressComponents(place.address_components, 'postal_code', 'short_name');
                        vm.form.country = vm.getAddressComponents(place.address_components, 'country', 'long_name');
                        vm.form.searchPlace = place.formatted_address;
                    }
                });
            }
            else if(this.view.transferService&&this.view.main){
                const autocompleteTo = new this.google.maps.places.Autocomplete(this.$refs.autocompleteInputTo.$el.querySelector('input'), {
                    componentRestrictions: { country: ["us", "ca"] },
                    fields: ["address_components", "formatted_address"],
                    types: ["address"],
                });
                autocompleteTo.addListener("place_changed", function() {
                    const place = autocompleteTo.getPlace();
                    if (!place.address_components){
                        return;
                    }else{
                        vm.form.addressTo = place.formatted_address;
                    }
                });
            }
        },
        setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            cvalue = {name: cvalue.name, address: cvalue.address, city: cvalue.city, state: cvalue.state, zip: cvalue.zip, email: cvalue.email, phone: cvalue.phone, checkbox: cvalue.checkbox}
            document.cookie = cname + "=" + JSON.stringify(cvalue) + ";" + expires + ";path=/";
        },
        getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        async convertFileUpload(image){
            if (image) {
                const reader = new FileReader();
                return new Promise((resolve) => {
                    reader.addEventListener('load', () => {
                        resolve(window.result = reader.result.split(',').pop());
                    }, false);
                    reader.readAsDataURL(image);
                });
            }
        },
        getAddressComponents(components, type, name) {
            let neighborhood = null;
            let administrative_area_level_5 = null;
            let administrative_area_level_4 = null;
            let administrative_area_level_3 = null;
            let administrative_area_level_2 = null;
            for (var key in components) {
                if (Object.prototype.hasOwnProperty.call(components, key)) {
                if (type === components[key].types[0]) {
                    return name === 'short_name' ? components[key].short_name : components[key].long_name;
                } else if (type === 'locality' && 'neighborhood' == components[key].types[0]) {
                    neighborhood = (name === 'short_name' ? components[key].short_name : components[key].long_name);
                } else if (type === 'locality' && 'administrative_area_level_5' == components[key].types[0]) {
                    administrative_area_level_5 = (name === 'short_name' ? components[key].short_name : components[key].long_name);
                } else if (type === 'locality' && 'administrative_area_level_4' == components[key].types[0]) {
                    administrative_area_level_4 = (name === 'short_name' ? components[key].short_name : components[key].long_name);
                } else if (type === 'locality' && 'administrative_area_level_3' == components[key].types[0]) {
                    administrative_area_level_3 = (name === 'short_name' ? components[key].short_name : components[key].long_name);
                } else if (type === 'locality' && 'administrative_area_level_2' == components[key].types[0]) {
                    administrative_area_level_2 = (name === 'short_name' ? components[key].short_name : components[key].long_name);
                }
                }
            }
            if (neighborhood != null) {
                return neighborhood;
            } else if (administrative_area_level_5 != null) {
                return administrative_area_level_5;
            } else if (administrative_area_level_4 != null) {
                return administrative_area_level_4;
            } else if (administrative_area_level_3 != null) {
                return administrative_area_level_3;
            } else if (administrative_area_level_2 != null) {
                return administrative_area_level_2;
            }
            return '';
        },
        modalGenerator(version) {
            if (version === 'requestSent') {
                const APIRESPONSE = this.apiValues.apiResponse ? JSON.parse(this.apiValues.apiResponse) : {};
                this.textDialog.title = this.$i18n.t('modals.requestSent.title');
                this.textDialog.feedback = APIRESPONSE.Message==='Your answer is incorrect.'?this.$i18n.t('modals.requestSent.feedbackAccount'):this.$i18n.t('modals.requestSent.feedback');
                this.textDialog.buttonLocations = false;
                // if(this.view.driverReview && this.form.driverRating >= 3.5){
                //     this.textDialog.googleRating = this.$i18n.t('modals.requestSent.googleRating');
                //     this.textDialog.buttonGoogleRating = true;
                // }
            }
            else if (version === 'invalidAnswers') {
                this.textDialog.title = this.$i18n.t('modals.invalidAnswers.title');
                this.textDialog.feedback = this.$i18n.t('modals.invalidAnswers.feedback');
                this.textDialog.buttonLocations = true;
            }
            else if (version === 'noDistrictFound') {
                this.textDialog.title = this.$i18n.t('modals.noDistrictFound.title');
                this.textDialog.feedback = this.$i18n.t('modals.noDistrictFound.feedback');
                this.textDialog.buttonLocations = true;
            }
            else if (version === 'districtFound') {
                this.textDialog.title = this.$i18n.t('modals.districtFound.title');
                this.textDialog.feedback = this.$i18n.t('modals.districtFound.feedback');
                this.textDialog.buttonLocations = false;
            }
            else if (version === 'timeOutError') {
                this.textDialog.title = this.$i18n.t('modals.timeOutError.title');
                this.textDialog.feedback = this.$i18n.t('modals.timeOutError.feedback');
                this.textDialog.buttonLocations = false;
            }
            else if (version === 'readError') {
                this.textDialog.title = this.$i18n.t('modals.readError.title');
                this.textDialog.feedback = this.$i18n.t('modals.readError.feedback');
                this.textDialog.buttonLocations = false;
            }
            this.standardDialog = true;
        },
        gTagSendEvents(label, value) {
            if (window.google_tag_manager && typeof window.dataLayer !== "undefined" && typeof label !== "undefined" && typeof value !== "undefined") {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                event: "eventTracking",
                action: "select_content",
                event_category: "engagement",
                event_label: label,
                value: value,
                });
            }
        },
        validate() {
            var vm = this;
            if(vm.$refs.form.validate()){
            this.loading = true;
            load(process.env.VUE_APP_RECAPTCHA).then((recaptcha) => {
                recaptcha.execute('validationContact').then(function (token) {
                    axios.post(process.env.VUE_APP_RESTURL+"services/apexrest/ServiceProvidedSearch", {
                        callType: "validation",
                        validationObject: {"token": token}
                    })
                    .then(function (response) {
                        if(response.status===200) {
                            var data = JSON.parse(response.data);
                            if(data.success && data.action === 'validationContact' && data.score >= 0.3){
                                vm.autocompleteSuggestions('address');
                            }else{
                                vm.recaptchaDialog = true;
                                vm.loading = false;
                            }
                        }else{
                            vm.loading = false;
                        }
                    })
                    .catch(function (error) {
                        vm.loading = false;
                        console.log(error);
                    });
                });
            });
            }
        },
        autocompleteSuggestions(inputAutoC) {
            var vm = this;
            if(vm.$refs.form.validate()){
                const service = new this.google.maps.places.AutocompleteService();
                const sessionToken = new this.google.maps.places.AutocompleteSessionToken();
                this.form.searchPlace = this.form.address + ', ' + this.form.city + ', ' + this.form.state + ' ' + this.form.zip;
                this.form.searchPlace = this.form.country?this.form.searchPlace + ', ' + this.form.country:this.form.searchPlace;
                var formInputAddress = inputAutoC!=='address'?this.form.addressTo:this.form.searchPlace;
                var currentAddress = {"description":'other',"structured_formatting":{"main_text":this.$i18n.t('universalFormContent.predictionText'), "secondary_text":vm.form.searchPlace}};
                service.getPlacePredictions({
                    componentRestrictions: { country: ['us', 'ca'] },
                    types: ['address'],
                    sessionToken: sessionToken,
                    input: formInputAddress,
                }, function (predictions, status) {
                    if (status == vm.google.maps.places.PlacesServiceStatus.OK) {
                        if(predictions.length===1){
                            vm.selectedPrediction(predictions[0].description, inputAutoC);
                            // vm.form.searchPlace = predictions[0].description;
                            // vm.viewStep('main');
                            // vm.loading = false;
                        }else if(predictions[0].description === formInputAddress){
                            vm.selectedPrediction(predictions[0].description, inputAutoC);
                            // vm.form.searchPlace = predictions[0].description;
                            // vm.viewStep('main');
                            // vm.loading = false;
                        }else{
                            if(inputAutoC!=='address'){
                                vm.form.addressTo = '';
                            }else{
                                predictions.push(currentAddress);
                                vm.form.predictions = predictions;
                                vm.viewStep('prediction');
                            }
                            vm.loading = false;
                        }
                    }else{
                        if(inputAutoC!=='address'){
                            vm.form.addressTo = '';
                        }else{
                            vm.form.street = vm.form.address.substr(0,vm.form.address.indexOf(' '));
                            vm.form.route = vm.form.address.substr(vm.form.address.indexOf(' ')+1);
                            vm.viewStep('main');
                            vm.loading = false;
                        }
                        vm.loading = false;
                    }
                });
            }
        },
        selectedPrediction(prediction, inputAutoC) {
            this.loading = true;
            var vm = this;
            if(prediction !== 'other'){
                const geocoder = new this.google.maps.Geocoder();
                geocoder.geocode({
                    address: prediction,
                }, function (res, status) {
                    if (status === 'OK') {
                        if(inputAutoC!=='addressTo'){
                            vm.form.street = vm.getAddressComponents(res[0].address_components, 'street_number', 'short_name');
                            vm.form.route = vm.getAddressComponents(res[0].address_components, 'route', 'short_name');
                            vm.form.city = vm.getAddressComponents(res[0].address_components, 'locality', 'short_name');
                            vm.form.state = vm.getAddressComponents(res[0].address_components, 'administrative_area_level_1', 'short_name');
                            vm.form.zip = vm.getAddressComponents(res[0].address_components, 'postal_code', 'short_name');
                            vm.form.country = vm.getAddressComponents(res[0].address_components, 'country', 'long_name');
                            vm.form.lat = res[0].geometry.location.lat();
                            vm.form.lng = res[0].geometry.location.lng();
                            vm.form.searchPlace = res[0].formatted_address;
                            vm.viewStep('main');
                            vm.loading = false;
                        }else{
                            vm.form.addressTo = res[0].formatted_address;
                            vm.verificationByAddress();
                            // vm.viewStep('main');
                            // vm.loading = false;
                        }
                    }else{
                        vm.loading = false;
                    }
                });
            }else{
                vm.form.street = vm.form.address.substr(0,vm.form.address.indexOf(' '));
                vm.form.route = vm.form.address.substr(vm.form.address.indexOf(' ')+1);
                vm.viewStep('main');
                vm.loading = false;
            }
        },
        async verificationByAddress(){
            var vm = this;
            if(vm.$refs.form.validate()&&!this.view.landfill){
                this.loading = true;
                if(vm.form.checkbox){
                    vm.setCookie('SiteSettings', vm.form, 14);
                }else{
                    vm.setCookie('SiteSettings', vm.form, -1);
                }
                const file = await this.convertFileUpload(this.form.fileUpload);
                axios.post(process.env.VUE_APP_RESTURL+"services/apexrest/PayNowSearch", {
                    name: this.form.name,
                    address: this.form.route,
                    street: this.form.street,
                    city: this.form.city,
                    state: this.form.state,
                    zip: this.form.zip,
                    country: this.form.country,
                    email: this.form.email,
                    phone: this.form.phone,
                    description: this.form.description,
                    type: this.form.type,
                    rollOffBox: this.form.rollOffBox?this.$i18n.t('universalFormContent.yes'):this.$i18n.t('universalFormContent.no'),
                    addressTo: this.form.addressTo,
                    datePickup: this.form.datePickup,
                    pickupBefore: this.form.pickupBefore,
                    pickupAfter: this.form.pickupAfter,
                    containerOut: this.form.containerOut,
                    upgradeService: this.form.upgradeService?this.form.upgradeService.join(", "):'',
                    driverRating: this.form.driverRating,
                    driverTime: this.form.driverTime,
                    fileUpload: file,
                    fileUploadType: this.form.fileUpload?this.form.fileUpload.type:'',
                    fileUploadName: this.form.fileUpload?this.form.fileUpload.name:'',
                    accountNumber: this.apiValues.accountNumber,
                    source: window.location.href,
                    lang: this.lang,
                    method: 'getVerificationLite'
                }).then(function (response) {
                    if (response.status===200) {
                        vm.apiValues.apiResponse = response.data;
                        var res = JSON.parse(response.data);
                        if (res.accountFound) {
                            vm.apiValues.questionsName = res.questions.name;
                            vm.apiValues.questionsEmail = res.questions.email;
                            vm.apiValues.questionsPhone = res.questions.phone;
                            vm.apiValues.sessionID = res.sessionID;
                            vm.questionDialog = true;
                            vm.loading = false;
                        }else if(res.districtNumber||(res.closestDistrictInfo&&res.closestDistrictInfo.District)) {
                            vm.sendEmailCustomerAndDistrict(true, true, true);// true false true
                        }else{
                            vm.sendEmailCustomerAndDistrict(true, false, false); // false, false, false
                        }
                    }else{
                        vm.loading = false;
                    }
                }).catch(function (error) {
                    vm.loading = false;
                    vm.sendEmailCustomerAndDistrict(true, false, false, 'Request Error'); // customerAction(false, false, 'read'); vm.sendEmailCustomerAndDistrict(true, true, 'read');
                    console.log(error);
                });
            }
            if(vm.$refs.form.validate()&&this.view.landfill){
                this.loading = true;
                if(vm.form.checkbox){
                    vm.setCookie('SiteSettings', vm.form, 14);
                }else{
                    vm.setCookie('SiteSettings', vm.form, -1);
                }
                axios.post(process.env.VUE_APP_RESTURL+"services/apexrest/PayNowSearch", {
                    name: this.form.name,
                    address: this.form.route,
                    street: this.form.street,
                    city: this.form.city,
                    state: this.form.state,
                    zip: this.form.zip,
                    country: this.form.country,
                    lat: this.form.lat,
                    lng: this.form.lng,
                    email: this.form.email,
                    phone: this.form.phone,
                    description: this.form.description,
                    type: this.form.type,
                    source: window.location.href,
                    templateName: 'Contact Us',
                    lang: this.lang,
                    location: this.assigntolocation,
                    method: 'sendLandfillCase'
                }).then(function (response) {
                    if(response.status === 200) {
                        var res = JSON.parse(response.data);
                        if(res[0].code!==202||res[1].code!==202||(res[2]&&res[2].code!==200)){
                            console.log(res[0].message,res[1].message,res[2]?res[2].message:'');
                        }
                        vm.customerAction(true, true);
                    } else {
                        vm.customerAction(false, false);
                    }
                }).catch(function (error) {
                    vm.customerAction(false, false, 'read');
                    console.log(error);
                });
            }
        },
        customerAction(validUser, validAnswers, error, apiResponse) {
            if (validUser && validAnswers && !error){
                this.modalGenerator('requestSent');
                this.questionDialog = false;
            }else if (validUser && !validAnswers && !error){
                this.resetDialog();
                this.modalGenerator('invalidAnswers');
            }else if (!validUser && !validAnswers && !error){
                this.modalGenerator('noDistrictFound');
            }else if (!validUser && validAnswers && !error){
                this.modalGenerator('districtFound', apiResponse);
            }else if (error ==="time") {
                this.modalGenerator('timeOutError');
                this.questionDialog = false;
            }else if (error==="read"||error==="Request Error") {
                this.modalGenerator('readError');
                this.questionDialog = false;
            }
        },
        validateAnswers(){
            this.loading = true;
            let vm = this;
            if(this.$refs.apiValues.validate()){
                axios.post(process.env.VUE_APP_RESTURL+"services/apexrest/PayNowSearch", {
                    sessionID: this.apiValues.sessionID,
                    answer: {
                        "name": this.apiValues.answerName,
                        "email": this.apiValues.answerEmail,
                        "phone": this.apiValues.answerPhone
                    },
                    method: 'getVerifyAnswerLite'
                }).then(function (response) {
                    if(response.status===200) {
                        vm.addBackClosestDistrictInfo(response.data);
                        var res = JSON.parse(vm.apiValues.apiResponse);
                        if(res.Message === 'Session has been completed before. Cannot be used again') {
                            vm.customerAction(false, false, 'time');
                        }
                        else if(res.accountFound){
                            vm.sendEmailCustomerAndDistrict(true, true, true);
                        } else {
                            if(res.districtNumber||(res.closestDistrictInfo&&res.closestDistrictInfo.District)) {
                                vm.sendEmailCustomerAndDistrict(true, true, true);// true false true
                            }else{
                                vm.sendEmailCustomerAndDistrict(true, true, false); // false, false, false
                            }
                        }
                    }else {
                        vm.loading = false;
                    }
                }).catch(function (error) {
                    vm.loading = false;
                    vm.customerAction(false, false, 'read');
                    console.log(error);
                });
            } else {
                vm.loading = false;
            }
        },
        async sendEmailCustomerAndDistrict(sendCase, validUser, validAnswers, error) {
            let vm = this;
            if(!sendCase){
                vm.sendCase(validUser, validAnswers);
            }else{
                const file = await this.convertFileUpload(this.form.fileUpload);
                if(this.form.description.toLowerCase().includes('sex')||this.form.description.toLowerCase().includes('porn')||this.form.description.toLowerCase().includes('nude')){
                    vm.customerAction(validUser, validAnswers, error, '');
                    vm.resetAll();
                    return;
                }
                axios.post(process.env.VUE_APP_RESTURL+"services/apexrest/PayNowSearch", {
                    name: this.form.name,
                    address: this.form.route,
                    street: this.form.street,
                    city: this.form.city,
                    state: this.form.state,
                    zip: this.form.zip,
                    country: this.form.country,
                    email: this.form.email,
                    phone: this.form.phone,
                    description: this.form.description,
                    type: this.form.type,
                    rollOffBox: this.form.rollOffBox?this.$i18n.t('universalFormContent.yes'):this.$i18n.t('universalFormContent.no'),
                    addressTo: this.form.addressTo,
                    datePickup: this.form.datePickup?this.formatDate(this.form.datePickup, this.canada?'en_CA':''):'',
                    pickupBefore: this.form.pickupBefore?this.formatDate(this.form.pickupBefore, this.canada?'en_CA':''):'',
                    pickupAfter: this.form.pickupAfter?this.formatDate(this.form.pickupAfter, this.canada?'en_CA':''):'',
                    stopRequestStatus: this.form.stopRequestStatus,
                    containerOut: this.form.containerOut,
                    cartType: this.form.cartType,
                    containerIssue: this.form.containerIssue,
                    upgradeService: this.form.upgradeService?this.form.upgradeService.join(", "):'',
                    driverRating: this.form.driverRating,
                    driverTime: this.form.driverTime?this.formatDate(this.form.driverTime, this.canada?'en_CA':''):'',
                    fileUpload: file,
                    fileUploadType: this.form.fileUpload?this.form.fileUpload.type:'',
                    fileUploadName: this.form.fileUpload?this.form.fileUpload.name:'',
                    apiResponse: error==="Request Error"?'{}':this.apiValues.apiResponse,
                    templateName: this.viewFormLabel(),
                    lang: this.lang,
                    location: this.assigntolocation,
                    method: 'sendMailToDistrictAndUser'
                }).then(function (response) {
                    if(response.status === 200) {
                        var res = JSON.parse(response.data);
                        if(res[0].code!==202||res[1].code!==202||(res[2]&&res[2].code!==200)){
                            console.log(res[0].message,res[1].message,res[2]?res[2].message:'');
                        }
                        vm.sendCase(validUser, validAnswers, error);
                    } else {
                        vm.sendCase(validUser, validAnswers, error);
                    }
                }).catch(function (error) {
                    vm.sendCase(validUser, validAnswers, 'read');
                    console.log(error);
                });
            }
        },
        sendCase(validUser, validAnswers, error) {
            var vm = this;
            let jsonParseAPI = vm.apiValues.apiResponse?JSON.parse(vm.apiValues.apiResponse):'';
            let fields = {
                orgid: process.env.VUE_APP_ORGANIZATIONID,
                '00N0Z00000H34lZ': 'No Robot',
                '00N0Z00000H34m3': window.location.href,
                '00Ni000000FrdHP': this.form.street+' '+this.form.route,
                '00Ni000000FrdHK': this.form.city,
                '00Ni000000FrdHO': this.form.state,
                '00Ni000000FrdHN': this.form.zip,
                '00Ni000000FrdHL': this.form.country,
                '00Ni000000FrdHR': this.$i18n.t('sendCase.fr'),
                '00N0Z00000H3KbB': 'Contact-Us Embed - Generic',
                [process.env.VUE_APP_DISTRICTCODE]: this.assigntolocation!==0?this.assigntolocation:jsonParseAPI.closestDistrictInfo&&jsonParseAPI.closestDistrictInfo.District?jsonParseAPI.closestDistrictInfo.District:jsonParseAPI.districtNumber?jsonParseAPI.districtNumber:'',
                [process.env.VUE_APP_ACCOUNTNUMBER]: jsonParseAPI.accountNumber?jsonParseAPI.accountNumber:'',
                [process.env.VUE_APP_WEBTYPE]: this.form.type?this.form.type:'',
                [process.env.VUE_APP_REQUESTERROR]: error==="Request Error"?1:0,
                [process.env.VUE_APP_EVENTDATE]:((this.view.driverReview&&this.form.driverTime)||(this.view.missedPickup||this.view.brokenContainer||this.view.deliveryIssue)&&this.form.datePickup)?vm.formatDate(this.view.driverReview?this.form.driverTime:this.form.datePickup):'',//Event_Date__c
                [process.env.VUE_APP_EVENTDETAILS]:this.form.containerOut?this.form.containerOut:'',//Event_Details__c
                [process.env.VUE_APP_TYPEOFCART]:this.form.cartType?this.form.cartType:'',//Type_of_Cart__c
                [process.env.VUE_APP_CONTAINERISSUE]:this.form.containerIssue?this.form.containerIssue:'',//Container_Issue__c
                [process.env.VUE_APP_ORDERAROLLOFFBOX]:this.form.rollOffBox?this.form.rollOffBox:'',//Order_a_Roll_Off_Box_Dumpster__c
                [process.env.VUE_APP_SERVICEUPGRADES]:this.form.upgradeService?this.form.upgradeService:'',//Service_Upgrades__c
                [process.env.VUE_APP_DRIVERRATING]:this.view.driverReview&&this.form.driverRating?this.form.driverRating:'',//Driver_Rating__c
                [process.env.VUE_APP_STARTDATE]:this.form.pickupAfter?vm.formatDate(this.form.pickupAfter):'',//Start_Date__c
                [process.env.VUE_APP_STOPDATE]:((this.view.iAmMoving||this.view.transferService)&&this.form.datePickup)||this.form.pickupBefore?vm.formatDate(this.view.iAmMoving||this.view.transferService?this.form.datePickup:this.form.pickupBefore):'',//Stop_Date__c
                [process.env.VUE_APP_STOPREQUESTSTATUS]: this.form.stopRequestStatus?this.form.stopRequestStatus:'',//Stop_Request_Status__c
                [process.env.VUE_APP_NEWADDRESS]:this.form.addressTo?this.form.addressTo:'',//New_Address__c
                external: 1,
                name: this.form.name,
                email: this.form.email,
                phone: this.form.phone,
                subject: this.viewFormLabel(),
                description: this.form.description,
                origin: 'Embed'
            };
            let customHiddenIframeName = 'CU_API';
            if (!document.getElementById(customHiddenIframeName)) {
                let theiFrame = document.createElement("iframe");
                theiFrame.id = customHiddenIframeName;
                theiFrame.name = customHiddenIframeName;
                theiFrame.src = 'about:blank';
                theiFrame.style.display = 'none';
                document.body.appendChild(theiFrame);
            }
            fields['retURL'] = 'https://';
            let form = document.createElement("form");
            form.method = "POST";
            form.action = "https://"+process.env.VUE_APP_DOMAIN+".salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";
            form.setAttribute("target", customHiddenIframeName);
            for (var fieldName in fields) {
                let theInput = document.createElement("input");
                theInput.name = fieldName;
                theInput.value = fields[fieldName];
                theInput.setAttribute("type", "hidden");
                form.appendChild(theInput);
            }
            document.body.appendChild(form);
            form.submit();
            setTimeout(() => {
                document.body.removeChild(document.getElementById(customHiddenIframeName));
                vm.customerAction(validUser, validAnswers, error, jsonParseAPI);
                vm.resetAll();
            }, 2000);
        },
        addBackClosestDistrictInfo(data) {
            const APIRESPONSE = JSON.parse(this.apiValues.apiResponse);
            if(APIRESPONSE.closestDistrictInfo&&APIRESPONSE.closestDistrictInfo.DistrictDetail&&APIRESPONSE.closestDistrictInfo.DistrictDetail.districtNumber){
                var apiReponse = JSON.parse(data);
                apiReponse.closestDistrictInfo = {};
                apiReponse.closestDistrictInfo = APIRESPONSE.closestDistrictInfo;
                apiReponse.closestDistrictInfo.District = APIRESPONSE.closestDistrictInfo.DistrictDetail.districtNumber;
                this.apiValues.apiResponse = JSON.stringify(apiReponse);
            }
        },
        formatDate(date, iso) {
            if(iso === 'en_CA'){
                return date.substring(0, 4) + "-" + date.substring(5, 7) + "-" + date.substring(8, 10);
            }else{
                return date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4);
            }
        },
        scrollToTop() {
            const getOffset = (element, horizontal = false) => {
                if(!element) return 0;
                return getOffset(element.offsetParent, horizontal) + (horizontal ? element.offsetLeft : element.offsetTop);
            }
            const element = document.getElementById('app');
            const X = getOffset(element) - (getOffset(element)/100 * 30);
            window.scrollTo(0,X);
        },
        resetAll() {
            this.resetForm();
            this.resetStep();
            this.resetData();
        },
        resetDialog() {
            this.standardDialog = false;
            this.questionDialog = false;
            this.textDialog.title = '';
            this.textDialog.feedback = '';
            this.textDialog.googleRating = '';
            this.textDialog.buttonLocations = false;
            this.textDialog.buttonGoogleRating = false;
            this.resetAll();
        },
        resetForm() {
            this.loading = false;
             if(this.service === 'landfill' || this.service === 'landfillinquiry' || this.light){
                this.view.index = false;
            }else{
                this.view.index = true;
            }
            if(this.light){
                this.view.contactUs = true;
            }else{
                this.view.contactUs = false;
            }
            if(this.service === 'landfill' || this.service === 'landfillinquiry'){
                this.view.landfill = true;
            }else{
                this.view.landfill = false;
            }
            this.view.bulkItemPickup = false;
            this.view.missedPickup = false;
            this.view.brokenContainer = false;
            this.view.deliveryIssue = false;
            this.view.iAmMoving = false;
            this.view.transferService = false;
            this.view.cancelService = false;
            this.view.stopService = false;
            this.view.serviceUpgrade = false;
            this.view.vacation = false;
            this.view.driverReview = false;
            this.view.requestQuote = false;
            this.view.startService = false;
            this.view.payMyBill = false;
            this.form.datePickupMenu = false;
            this.form.pickupBeforeMenu = false;
            this.form.pickupAfterMenu = false;
            this.form.driverTimeMenu = false;
        },
        resetStep() {
            if(this.service === 'landfill' || this.service === 'landfillinquiry' || this.light){
                this.$refs.form.resetValidation();
                this.view.address = true;
            }else{
                this.view.address = false;
            }
            this.view.prediction = false;
            this.view.main = false;
        },
        resetData() {
            if(!this.getCookie('SiteSettings')){
                this.form.name = '';
                this.form.address =  '';
                this.form.city = '';
                this.form.state = '';
                this.form.zip = '';
                this.form.email = '';
                this.form.phone = '';
                this.form.checkbox = '';
            }
            this.form.searchPlace = '';
            this.form.street = '';
            this.form.route = '';
            this.form.country = '';
            this.form.description = '';
            this.form.type = undefined;
            this.form.rollOffBox = '';
            this.form.acceptConditions = '',
            this.form.addressTo = '';
            this.form.datePickup = '';
            this.form.pickupBefore = '';
            this.form.pickupAfter = '';
            this.form.containerOut = '';
            this.form.stopRequestStatus = '';
            this.form.cartType = '';
            this.form.containerIssue = '';
            this.form.upgradeService = '';
            this.form.driverRating = 4;
            this.form.driverTime = null;
            this.form.fileUpload = null;
            this.apiValues.sessionID = '';
            this.apiValues.apiResponse = '';
            this.apiValues.questionsName = [];
            this.apiValues.questionsEmail = [];
            this.apiValues.questionsPhone = [];
            this.apiValues.answerName = '';
            this.apiValues.answerPhone = '';
            this.apiValues.answerEmail = '';
        },
        viewFormLabel() {
            if(this.view.contactUs){
                return 'Contact Us';
            }else if(this.view.landfill){
                return 'Landfill';
            }else if(this.view.bulkItemPickup){
                return 'Bulk Item Pickup';
            }else if(this.view.missedPickup){
                return 'Missed Pickup';
            }else if(this.view.brokenContainer){
                return 'Broken Container';
            }else if(this.view.deliveryIssue){
                return 'Delivery Issue';
            }else if(this.view.iAmMoving){
                return 'I Am Moving';
            }else if(this.view.transferService){
                return 'Transfer Service';
            }else if(this.view.stopService){
                return 'Stop Service';
            }else if(this.view.cancelService){
                return 'Cancel Service';
            }else if(this.view.serviceUpgrade){
                return 'Service Upgrade';
            }else if(this.view.vacation){
                return 'Vacation';
            }else if(this.view.driverReview){
                return 'Driver Review';
            }else if(this.view.requestQuote){
                return 'Request Quote';
            }else if(this.view.startService){
                return 'Start Service';
            }
        },
        viewForm(viewFormSelected, skipScroll) {
            var vm = this;
            let position = 0;
            this.view.index = false;
            this.view.contactUs = false;
            this.view.landfill = false;
            this.view.bulkItemPickup = false;
            this.view.missedPickup = false;
            this.view.brokenContainer = false;
            this.view.deliveryIssue = false;
            this.view.iAmMoving = false;
            this.view.transferService = false;
            this.view.stopService = false;
            this.view.cancelService = false;
            this.view.serviceUpgrade = false;
            this.view.vacation = false;
            this.view.driverReview = false;
            this.view.requestQuote = false;
            this.view.startService = false;
            this.view.payMyBill = false;
            if(viewFormSelected === 'contactUs' || viewFormSelected === 'contactus' || viewFormSelected === 'generalinquiry'){
                position = 10;
                this.view.contactUs = true;
                this.viewStep('address', skipScroll);
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'landfill' || viewFormSelected === 'landfillinquiry'){
                position = 0;
                this.view.landfill = true;
                this.viewStep('address', skipScroll);
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'bulkItemPickup' || viewFormSelected === 'bulkitempickup'){
                position = 0;
                if(this.bulkitempickup){
                    this.view.payMyBill = true;
                    if (window.google_tag_manager && typeof window.dataLayer !== "undefined") {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                        event: 'eventTracking',
                        action: 'select_content',
                        event_category: 'engagement',
                        event_label: viewFormSelected,
                        value: position,
                        eventCallback: function() {
                            window.location.href=vm.bulkitempickup;
                        },
                        eventTimeout: 2000,
                        });
                    } else {
                        window.location.href=vm.bulkitempickup;
                    }
                }else{
                    this.view.bulkItemPickup = true;
                    this.viewStep('address', skipScroll);
                    this.gTagSendEvents(viewFormSelected,position);
                }
            }else if(viewFormSelected === 'missedPickup' || viewFormSelected === 'missedpickup'){
                position = 4;
                this.view.missedPickup = true;
                this.viewStep('address', skipScroll);
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'brokenContainer' || viewFormSelected === 'brokencontainer'){
                position = 2;
                this.view.brokenContainer = true;
                this.viewStep('address', skipScroll);
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'deliveryIssue' || viewFormSelected === 'deliveryissue'){
                position = 9;
                this.view.deliveryIssue = true;
                this.viewStep('address', skipScroll);
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'iAmMoving' || viewFormSelected === 'iammoving'){
                position = 6;
                this.view.iAmMoving = true;
                this.viewStep('address', skipScroll);
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'transferService' || viewFormSelected === 'transferservice'){
                position = 3;
                this.view.transferService = true;
                this.viewStep('address', skipScroll);
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'cancelService' || viewFormSelected === 'cancelservice'){
                position = 8;
                this.view.cancelService = true;
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'stopService' || viewFormSelected === 'stopservice'){
                position = 8;
                this.view.stopService = true;
                this.viewStep('address', skipScroll);
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'serviceUpgrade' || viewFormSelected === 'serviceupgrade'){
                position = 7;
                this.view.serviceUpgrade = true;
                this.viewStep('address', skipScroll);
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'vacation' || viewFormSelected === 'seasonvacationhold'){
                position = 1;
                this.view.vacation = true;
                this.viewStep('address', skipScroll);
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'driverReview' || viewFormSelected === 'driverreview' || viewFormSelected === 'appreciatemydriver'){
                position = 5;
                this.view.driverReview = true;
                this.viewStep('address', skipScroll);
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'requestQuote' || viewFormSelected === 'requestquote'){
                position = 11;
                this.view.requestQuote = true;
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'startService' || viewFormSelected === 'startservice'){
                position = 12;
                this.view.startService = true;
                this.gTagSendEvents(viewFormSelected,position);
            }else if(viewFormSelected === 'payMyBill' || viewFormSelected === 'paymybill'){
                position = 13;
                this.view.payMyBill = true;
                if (window.google_tag_manager && typeof window.dataLayer !== "undefined") {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                    event: 'eventTracking',
                    action: 'select_content',
                    event_category: 'engagement',
                    event_label: viewFormSelected,
                    value: position,
                    eventCallback: function() {
                        window.location.href=vm.pay;
                    },
                    eventTimeout: 2000,
                    });
                } else {
                    window.location.href=vm.pay;
                }
            }
            if(!skipScroll){
                this.scrollToTop();
            }
        },
        viewStep(viewStepSelected, skipScroll){
            this.view.address = false;
            this.view.prediction = false;
            this.view.main = false;
            if(viewStepSelected === 'address'){
                this.view.address = true;
            }else if(viewStepSelected === 'prediction'){
                this.view.prediction = true;
            }else if(viewStepSelected === 'main'){
                this.view.main = true;
            }
            if(!skipScroll){
                this.scrollToTop();
            }
        },
    },
}
</script>