export const loadAdditionalLibrary = (apiKey, library) => {
    return new Promise((resolve, reject) => {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            if (!window.google.maps[library]) {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${library}`;
                script.async = true;
                script.onload = () => {
                    resolve(window.google);
                };
                script.onerror = (error) => {
                    reject(error);
                };
                document.head.appendChild(script);
            } else {
                resolve(window.google);
            }
        } else {
            reject(new Error('Google Maps API is not loaded.'));
        }
    });
};
